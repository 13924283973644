"use strict";
/*!
 * Copyright 2017-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
Object.defineProperty(exports, "__esModule", { value: true });
var lib_storage_1 = require("@aws-sdk/lib-storage");
var client_s3_1 = require("@aws-sdk/client-s3");
exports.default = (function (fileField, _a) {
    var credentials = _a.credentials;
    var Bucket = fileField.bucket, Key = fileField.key, region = fileField.region, ContentType = fileField.mimeType, Body = fileField.localUri;
    var target = { Bucket: Bucket, Key: Key, Body: Body, ContentType: ContentType };
    var parallelUploads3 = new lib_storage_1.Upload({
        client: new client_s3_1.S3({ credentials: credentials, region: region }) || new client_s3_1.S3Client({ credentials: credentials, region: region }),
        leavePartsOnError: false,
        params: target,
    });
    return parallelUploads3.done();
});
