"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var apollo_utilities_1 = require("apollo-utilities");
var apollo_link_1 = require("apollo-link");
var sha256_js_1 = require("@aws-crypto/sha256-js");
var util_hex_encoding_1 = require("@aws-sdk/util-hex-encoding");
exports.passthroughLink = function (op, forward) { return (forward ? forward(op) : apollo_link_1.Observable.of()); };
exports.isUuid = function (val) { return typeof val === 'string' && val.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i); };
exports.getOperationFieldName = function (operation) { return apollo_utilities_1.resultKeyNameFromField(operation.definitions[0].selectionSet.selections[0]); };
exports.hash = function (src) {
    debugger;
    var arg = src || {};
    var hash = new sha256_js_1.Sha256();
    hash.update(arg);
    return util_hex_encoding_1.toHex(hash.digestSync());
};
var logger_1 = require("./logger");
exports.rootLogger = logger_1.default;
